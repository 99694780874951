
import {Vue, Component} from 'vue-property-decorator'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import QuoteIndexExpiration from '@/components/QuoteIndexExpiration.vue'
import QuoteIndexId from '@/components/QuoteIndexId.vue'
import QuoteIndexPrice from '@/components/QuoteIndexPrice.vue'
import QuoteIndexMobileCard from '@/components/QuoteIndexMobileCard.vue'
import QuoteIndexNotFound from '@/components/QuoteIndexNotFound.vue'
import Main from '@/layouts/Main.vue'
import { DataTableColumn } from '@/models/DataTableColumn'
import { filter } from '@/utils/filter'
import { TableViewQuote } from '@/models/dto'
import { EventBus } from '@/utils/eventBus'
import quotes from '@/services/quotes'
import { formatPickupDestinationTextFromAddresses } from '@/utils/string'
import dayjs from 'dayjs'
import SalesWillCallDialog from '@/components/SalesWillCallDialog.vue'
import QuoteExpiredDialog from '@/components/QuoteExpiredDialog.vue'
import PromptCallDialog from '@/components/PromptCallDialog.vue'
import PromptCallForBASDialog from '@/components/PromptCallForBASDialog.vue'
import { TableViewFilter } from '@/models/TableView'
import { ActionColumn } from '@/models/ActionColumn'
import { RawLocation } from 'vue-router'
import { isExpired, willSalesCall } from '@/utils/quote'
import ga4 from '@/store/modules/ga4'
import { PricingMethod, ViewQuoteSource } from '@/utils/enum'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'
import auth from '@/store/modules/auth'
import { DateTime } from 'luxon'

@Component({
  components: {
    CUCollectionTable,
    Main,
    SalesWillCallDialog,
    QuoteExpiredDialog,
    PromptCallDialog,
    QuoteIndexMobileCard,
    QuoteIndexNotFound,
    PromptCallForBASDialog,
  },
})
export default class QuoteIndex extends Vue {
  filters: any = filter()
  tableView = quotes.tableView
  loading = true

  quoteExpiredDialogIsOpen = false
  promptCallDialogIsOpen = false
  salesWillCallDialogIsOpen = false
  promptCallForBASDialogIsOpen = false
  vehicleCount = 0
  createdOn = DateTime.now().toISO()
  customerPhoneNumber = ''

  columns: DataTableColumn[] = [
    {
      _t_id: 'f04b26fe-1cb1-4e6c-85e1-4b418eee26af',
      elementId: 'quote-id',
      text: this.$t('common.ID'),
      value: 'quoteId',
      type: 'slot',
      component: QuoteIndexId,
    },
    {
      _t_id: '4267bf8c-9dc7-4ee1-b1e6-0e02d3562376',
      elementId: 'price',
      text: this.$t('common.PRICE'),
      value: 'price',
      type: 'slot',
      component: QuoteIndexPrice,
    },
    {
      _t_id: 'f0586dc6-59f6-4892-ae68-431380386e4d',
      elementId: 'created-on',
      text: this.$t('quotesTable.QUOTED_ON'),
      value: 'createdOn',
      computedText: (row: TableViewQuote): string =>
        dayjs(row.createdOn).format('MM/DD/YYYY'),
    },
    {
      _t_id: 'a978dc5b-7320-40a8-bbc5-62b5ccc35c26',
      elementId: 'pickup-date',
      text: this.$t('common.PICKUP_DATE'),
      value: 'pickupDate',
      computedText: (row: TableViewQuote): string => {
        const firstPickup = row.trips?.[0]?.stops?.find(
          (stop) => stop.orderIndex === 0
        )
        return this.$dayjs(firstPickup.pickupDate)
          .tz(firstPickup.address.timeZone)
          .format('MM/DD/YYYY')
      },
    },
    {
      _t_id: '1819dcb9-0eca-4de9-a5ce-0d7f165f7e9e',
      elementId: 'pickup-dropoff',
      text: this.$t('quotesTable.PICKUP_DROPOFF'),
      value: 'pickup',
      computedText: (row: TableViewQuote): string => {
        const stops = row.trips?.[0]?.stops
        const firstPickup = stops.find((stop) => stop.orderIndex === 0)
        const firstDropoff = stops.find((stop) => stop.orderIndex === 1)
        return formatPickupDestinationTextFromAddresses(
          firstPickup.address,
          firstDropoff.address,
          '>'
        )
      },
    },
    {
      _t_id: 'c8881afc-384c-4815-8ec9-cc19a8c6e696',
      elementId: 'customer-name',
      text: this.$t('quotesTable.BOOKING_CONTACT'),
      value: 'customerName',
      hidden: this.hideBookingContactColumn,
    },
    {
      _t_id: '57bc27e4-be5f-498c-aa2b-f4937dcca50f',
      elementId: 'customer-account-name',
      text: this.$t('quotesTable.TEAM'),
      value: 'customerAccountName',
      hidden: this.hideTeamColumn,
    },
    {
      _t_id: '13e7d61e-d34b-4ba4-8763-035ea8e995de',
      elementId: 'expiration',
      text: this.$t('quotesTable.EXPIRATION'),
      value: 'expirationDate',
      type: 'slot',
      component: QuoteIndexExpiration,
    },
    {
      _t_id: '4632622e-6507-4a2b-ab15-d5422afffc0d',
      elementId: 'actions',
      text: '',
      value: 'actions',
      type: 'actions',
    },
  ]

  actions: ActionColumn[] = [
    {
      displayText: this.$t('common.DETAILS'),
      key: 'details',
      color: 'primary',
      icon: '',
      confirmModal: false,
      ariaLabel: 'View Quote Details',
      isDetail: true,
      hideOn: (row: TableViewQuote): boolean =>
        (isExpired(row.expirationDate) && row.isCharterUpQuote) ||
        row.promptCall ||
        row.salesWillCall ||
        row.promptBASCall,
      detailRoute: (row: TableViewQuote): RawLocation =>
        this.getRowClickRoute(row),
    },
    {
      displayText: this.$t('common.DETAILS'),
      key: 'details',
      color: 'primary',
      ariaLabel: this.$t('common.DETAILS'),
      icon: '',
      isButton: true,
      action: (row: TableViewQuote): void => {
        this.preHandleClickRowCheck(row)
      },
      hideOn: (row: TableViewQuote): boolean => {
        return !(
          (isExpired(row.expirationDate) && row.isCharterUpQuote) ||
          row.promptCall ||
          row.salesWillCall ||
          row.promptBASCall
        )
      },
    },
  ]

  initialFilters: TableViewFilter[] = [
    {
      column: {
        _t_id: '79425299-7ec4-44f1-a408-803fd5329d9c',
        value: 'isConverted',
        filterType: 'eq',
        text: '',
      },
      value: 0,
    },
  ]

  mounted(): void {
    this.processQueryParams()
    EventBus.$on('open-quote-expired-dialog', () => {
      this.quoteExpiredDialogIsOpen = true
    })
    EventBus.$on('open-prompt-call-dialog', () => {
      this.promptCallDialogIsOpen = true
    })
    EventBus.$on('open-sales-will-call-dialog', () => {
      this.salesWillCallDialogIsOpen = true
    })
    EventBus.$on('open-prompt-call-for-bas-dialog', () => {
      this.promptCallForBASDialogIsOpen = true
    })
  }

  async supplementalRowMethod(row: TableViewQuote): Promise<void> {
    try {
      if (this.isQuoteExpired(row)) {
        return
      }
      const quoteDetailResponse = await quotes.detailV2(row.quoteId, false)
      const quoteDetail: QuoteDetailV2 = quoteDetailResponse.data.data
      row.quoteDetail = quoteDetail
      row.salesWillCall = willSalesCall(quoteDetail)
      row.promptCall = quoteDetail.isLastMinuteTrip
      row.promptBASCall = quoteDetail.isBillAfterServicesAndWithin4Days
    } catch (err) {
      console.error(err)
    }
  }

  async setModalProps(quoteId: number) {
    try {
      const quoteDetailResponse = await quotes.detailV2(quoteId, false)
      const quoteDetail: QuoteDetailV2 = quoteDetailResponse.data.data
      this.vehicleCount = quoteDetail?.trips[0]?.vehicles?.reduce(
        (sum, vehicle) => sum + vehicle.quantity,
        0
      )
      this.createdOn = quoteDetail?.createdOn
      this.customerPhoneNumber = quoteDetail?.customer?.phone
    } catch (err) {
      console.error(err)
    }
  }

  get noSubAccounts(): boolean {
    return auth.customerSubAccounts.length === 0
  }

  get hideBookingContactColumn(): boolean {
    const noOtherUsersInAccount = auth?.customerAccount?.customers?.length <= 1
    return this.noSubAccounts && noOtherUsersInAccount
  }

  get hideTeamColumn(): boolean {
    return this.noSubAccounts
  }

  isQuoteExpired(row: TableViewQuote): boolean {
    const expiration = row?.expirationDate
    if (!expiration) {
      return true
    }
    const now = new Date()
    const thenDate = new Date(`${expiration.split('+')[0]}Z`)
    return now.getTime() - thenDate.getTime() > 0
  }

  postFirstLoadHandler(items: TableViewQuote[]): void {
    const isOnlyOneQuote = items?.length === 1
    if (!isOnlyOneQuote) {
      return
    }

    const quote = items[0]
    if (quote.isSelfServe || isExpired(quote.expirationDate)) {
      return
    }

    this.$router.push(
      this.getQuoteDetailRoute(quote.quoteId, quote.isConfirmed)
    )
  }

  getRowClickRoute(row: TableViewQuote): RawLocation {
    if (!row.isCharterUpQuote) {
      return this.getGuestCheckout(row)
    }
    const isCategory =
      row?.quoteDetail?.pricingMethod === PricingMethod.Category
    if (isCategory) {
      return this.getSingleBidCheckoutRoute(row.quoteId)
    } else {
      return this.getQuoteDetailRoute(row.quoteId, row.isConfirmed)
    }
  }

  preHandleClickRowCheck(row: TableViewQuote): boolean {
    if (isExpired(row.expirationDate)) {
      this.quoteExpiredDialogIsOpen = true
      return false
    }
    if (row.promptCall) {
      this.promptCallDialogIsOpen = true
      return false
    }
    if (row.salesWillCall) {
      this.setModalProps(row.quoteId)
      this.salesWillCallDialogIsOpen = true
      return false
    }
    if (row.promptBASCall) {
      this.promptCallForBASDialogIsOpen = true
      return false
    }
    return true
  }

  handleClickRow(row: TableViewQuote): void {
    const continueToClickAction = this.preHandleClickRowCheck(row)
    if (!continueToClickAction) {
      return
    }
    const route = this.getRowClickRoute(row)
    if (!route) {
      return
    }
    this.setModalProps(row.quoteId)
    ga4.setViewQuoteSource(ViewQuoteSource.QuoteTable)
    this.$router.push(route)
  }

  getGuestCheckout(row: TableViewQuote): RawLocation {
    return {
      name: 'guest-checkout',
      params: { hash: row.hash },
    }
  }

  getSingleBidCheckoutRoute(quoteId: number): RawLocation {
    return {
      name: 'checkout-single-bid',
      params: { quoteId: quoteId.toString() },
    }
  }

  getQuoteDetailRoute(quoteId: number, isConfirmed: boolean): RawLocation {
    const params = {
      id: quoteId.toString(),
      mode: !isConfirmed ? 'confirm' : 'bids',
    }
    return {
      name: 'quote-detail',
      params,
    }
  }

  processQueryParams(): void {
    const query = this.$route.query
    const { isLastMinuteTrip, isBillAfterServicesAndWithin4Days, isLargeEvent, isLongTermShuttle, quoteId } = query

    if (isLastMinuteTrip === 'true') {
      this.promptCallDialogIsOpen = true
    } else if (isBillAfterServicesAndWithin4Days === 'true') {
      this.promptCallForBASDialogIsOpen = true
    } else if (
      isLargeEvent === 'true' ||
      isLongTermShuttle === 'true'
    ) {
      if (quoteId) {
        this.setModalProps(Number(quoteId))
      }
      this.salesWillCallDialogIsOpen = true
    }

    // if query is already empty, do not replace it
    if (Object.keys(query).length) {
      this.$router.replace({ query: null })
    }
  }
}
